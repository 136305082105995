import React from 'react'
import Nav from '../components/Nav'

const NotFoundPage = () => (
  <>
  <Nav />
  <h1>Noe gikk galt</h1>
  </>
)

export default NotFoundPage
